import React from 'react';
import { motion } from 'framer-motion';
import HomeAboutImage from '../../images/about.png';
import Agro from '../../images/agro.png';
import TeleComm from '../../images/tele.png';
import GeneralServ from '../../images/janiba-globe.png';
import PageHeader from '../PageHeader';

const coreServ = [
  {
    id: 1,
    title: 'Agro-Allied Services:',
    content:
      'Janiba Intercontinental Network Ltd has a deep-rooted connection to the agricultural industry, offering comprehensive agro-allied services. We collaborate with local farmers and agribusinesses to enhance agricultural productivity and promote sustainable farming practices. Our services encompass crop cultivation, crop management, and market access solutions. We are committed to driving growth in the agricultural sector by ensuring high yields, improved crop quality, and increased profitability for our partners.',
    image: Agro,
  },
  {
    id: 2,
    title: 'Telecommunication:',
    content:
      "We excel in the realm of telecommunication by providing cutting-edge solutions to meet the growing demand for seamless communication. Our team of experts is well-versed in network infrastructure, IT services, and telecom project management. We offer state-of-the-art telecom services, including network design, installation, and maintenance, enabling businesses and individuals to stay connected and thrive in today's digital age.",
    image: TeleComm,
  },
  {
    id: 3,
    title: 'Exportation of Locally Produced Goods:',
    content:
      'Janiba Intercontinental Network Ltd plays a pivotal role in promoting locally produced goods to the global market. We facilitate the export of high-quality, locally sourced products, ensuring they meet international standards and regulations. Our focus extends to a wide range of commodities, including agricultural produce, processed foods, and artisanal crafts. Our commitment to excellence in exportation opens doors for local businesses to access global markets.',
    image: GeneralServ,
  },
];

const textShow = {
  initial: {
    opacity: 0,
    x: 200,
  },
  whileInView: {
    opacity: 1,
    x: 0,
  },
};

function CoreServices() {
  return (
    <div
      className="about-us min-h-[50vh] py-48 sm:py-24 px-32 lg:px-24 md:px-16 sm:px-4 items-center justify-center bg-gray text-darkgray"
      id="core"
    >
      <PageHeader title={'Core Services'} />

      <div className="grid grid-cols-9 lg:grid-cols-8 gap-6  overflow-hidden">
        {coreServ.map((cor) => (
          <motion.div
            key={cor.id}
            initial={{ opacity: 0, x: 200 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { delay: 0.5, duration: 0.5 },
            }}
            viewport={{ once: true }}
            className="col-span-3 lg:col-span-4 sm:col-span-8 shadow-md p-4 bg-purewhite"
          >
            <h1 className="text-2xl text-maincolor font-bold text-center underline underline-offset-4 decoration-black">
              {cor.title}
            </h1>
            <img
              src={cor.image}
              alt="core"
              className="w-full h-[150px] my-4 float-right"
            />
            <p className="leading-6 text-justify text-sm">{cor.content}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default CoreServices;
