import React from 'react';
import PageHeader from '../PageHeader';
import { TypeAnimation } from 'react-type-animation';

function WhyChooseUS() {
  return (
    <div
      className="py-48 sm:py-24 px-32 xl:px-24 lg:px-16 md:px12 sm:px-4 bg-darkgray text-purewhite min-h-[50vh]"
      id="why"
    >
      <PageHeader title={'Why Choose Us:'} className="text-purewhite" />
      <div className="flex flex-col items-center justify-center h-[30vh]">
        <div className="text-center space-y-6 overflow-hidden">
          <h1 className="text-2xl text-seccolor font-bold">
            Janiba Intercontinental Network Ltd.
          </h1>
          <h2 className="italic text-lg animate-pulse text-maincolor">means</h2>
          <TypeAnimation
            sequence={[
              'Quality Assurance',
              1000, // wait time 1s
              'Industry Expertise',
              1000,
              'Local and Global Reach',
              1000,
              'Sustainability Focus',
              1000,
              'Customized Solutions',
              1000,
              'Innovation and Technology',
              1000,
              'Customer-Centric Approach',
              1000,
              'Global Standards Compliance',
              1000,
              'Partnerships and Collaborations',
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{
              fontSize: '2em',
              fontWeight: 'bold',
              display: 'inline-block',
            }}
            repeat={Infinity}
          />
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUS;
