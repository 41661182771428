import React from 'react';
import { motion } from 'framer-motion';
import ImageGallery from 'react-image-gallery';
import HeroImage from '../../images/janiba-globenn.png';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Link } from 'react-router-dom';
import ParticlesBackground from '../ParticlesBackground';

const quote = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.5,
    },
  },
};

const singleWord = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

const smallText = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1 } },
};

const buttonAni = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { delay: 1.5 } },
};

function Hero() {
  const heroText = 'Janiba Intercontinental Network Ltd.';
  return (
    <div className="px-32 py-8 xl:px-24 lg:px-16 md:px-12 sm:px-8 bg-gray">
      <div className="h-[80vh] flex md:flex-col items-center justify-center md:text-center">
        <div className="flex-1 md:order-2 space-y-6">
          <ParticlesBackground />
          <motion.h1
            className="text-6xl lg:text-5xl md:text-4xl md:mt-6 capitalize font-bold mb-3 text-gray-800"
            variants={quote}
            initial="initial"
            animate="animate"
          >
            {heroText.split(' ').map((word, index) => (
              <motion.span
                key={word + '-' + index}
                className="inline-block"
                variants={singleWord}
                initial="initial"
                animate="animate"
              >
                {word}&nbsp;
              </motion.span>
            ))}
          </motion.h1>
          <motion.p
            variants={smallText}
            initial="initial"
            animate="animate"
            className="text-2xl md:text-sm text-gray-600 capitalize"
          >
            {' '}
            a world of services tailored to meet your demands and elevate your
            business to new heights.
          </motion.p>
          <div>
            <Link href={'#about'}>
              <motion.button
                variants={buttonAni}
                initial="initial"
                animate="animate"
                className="bg-gradient-to-r from-seccolor to-black text-whitesmokecolor hover:bg-gradient-to-l uppercase py-4 px-12 md:py-3 md:px-6 rounded-md transition-all duration-300 ease-in-out hover:bg-white hover:text-gray-700"
              >
                Know More
              </motion.button>
            </Link>
          </div>
        </div>
        <div className="flex-1 md:order-1 space-y-6">
          <img src={HeroImage} alt="hero" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
