import React from "react";
import Nav from "./nav/Nav";
import Footer from "./footer/Footer";

function Layout({ children }) {
  return (
    <div>
      <Nav />
      <div className="pt-[100px]">{children}</div>
      <Footer />
    </div>
  );
}

export default Layout;
