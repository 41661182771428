import React from 'react';
import Hero from '../components/hero/Hero';
import About from '../components/home/About';
import WhyChooseUS from '../components/home/WhyChooseUS';
import CoreServices from '../components/home/CoreServices';
// import Portfolio from "../components/Portfolio";

function index() {
  return (
    <div>
      <Hero />
      <About />
      <CoreServices />
      <WhyChooseUS />
      {/* <Portfolio /> */}
    </div>
  );
}

export default index;
