import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Logo from '../../images/jinl-logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const CustomLink = ({ href, title, className = '' }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Link
      href={href}
      className={`${className} relative group text-black hover:text-seccolor transition-all duration-500 ease`}
    >
      {title}
      <span
        className={`h-[2px] inline-block  bg-gradient-to-r from-seccolor to-seccolor  absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === href ? 'w-full' : 'w-0'
        }`}
      >
        &nbsp;
      </span>
    </Link>
  );
};

const CustomMobileLink = ({ href, title, className = '', toggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClick = () => {
    toggle();
    navigate(href);
  };
  return (
    <button
      href={href}
      className={`${className} relative group uppercase `}
      onClick={handleClick}
    >
      {title}
      <span
        className={`h-[2px] inline-block  bg-gradient-to-r from-seccolor to-seccolor  absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-500 ${
          currentPath === href ? 'w-full' : 'w-0'
        }`}
      >
        &nbsp;
      </span>
    </button>
  );
};

function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="shadow-lg fixed z-30 w-full bg-gray">
      <div className="bg-gradient-to-r from-purewhite to-gray flex justify-between items-center px-48 py-2 relative xl:px-24 lg:px-16 md:px12 sm:px-8">
        <Link
          href={'/'}
          className="mb-3 flex flex-col justify-center items-center"
        >
          <img src={Logo} alt="Logo" className="w-[100px]" />
          <p className="text-seccolor font-bold text-[8px]">RC No.: 7160194</p>
        </Link>
        <div className="space-x-5 text-gray-400 uppercase lg:hidden">
          <CustomLink href={'/'} title="Home">
            Home
          </CustomLink>
          <CustomLink href={'#about'} title="About">
            About us
          </CustomLink>
          <CustomLink href={'#core'} title="Core Services">
            Core Services
          </CustomLink>
          <CustomLink href={'#why'} title="Why Choose Us">
            Why Choose Us
          </CustomLink>
          {/* <CustomLink href={"#portfolio"} title="Portfolio">
            Portfolio
          </CustomLink> */}
          <CustomLink href={'#footer'} title="Contact">
            Contact us
          </CustomLink>
        </div>

        {isOpen ? (
          <motion.div
            className="min-w-[70vw] flex flex-col justify-center items-center z-30 space-y-3 bg-seccolor/90 rounded-lg backdrop-blur-md py-32 px-12 text-purewhite fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 "
            initial={{ scale: 0, opacity: 0, x: '-50%', y: '-50%' }}
            animate={{ scale: 1, opacity: 1, transition: { duration: 1 } }}
          >
            <CustomMobileLink href={'/'} title="Home" toggle={handleClick}>
              Home
            </CustomMobileLink>
            <CustomMobileLink
              href={'#about'}
              title="About"
              toggle={handleClick}
            >
              About us
            </CustomMobileLink>
            <CustomMobileLink
              href={'#core'}
              title="Core Services"
              toggle={handleClick}
            >
              Core Services
            </CustomMobileLink>
            <CustomMobileLink
              href={'#why'}
              title="Why Choose Us"
              toggle={handleClick}
            >
              Why Choose Us
            </CustomMobileLink>
            {/* <CustomMobileLink
              href={"#portfolio"}
              title="Portfolio"
              toggle={handleClick}
            >
              Portfolio
            </CustomMobileLink> */}
            <CustomMobileLink
              href={'#footer'}
              title="Contact"
              toggle={handleClick}
            >
              Contact us
            </CustomMobileLink>
          </motion.div>
        ) : null}

        <button
          className=" absolute right-8 top-12 flex-col justify-center items-center hidden lg:flex"
          onClick={handleClick}
        >
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? 'rotate-45 translate-y-1' : ' -translate-y-0.5'
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
              isOpen ? 'opacity-0' : 'opacity-100'
            }`}
          ></span>
          <span
            className={`bg-maincolor block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm ${
              isOpen ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'
            }`}
          ></span>
        </button>
      </div>
    </nav>
  );
}

export default Nav;
