import React from 'react';
import {
  FaMobileAlt,
  FaEnvelopeOpen,
  FaArrowUp,
  FaMapMarker,
} from 'react-icons/fa';
import Logo from '../../images/jinl-logo.png';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer className=" bg-black text-whitesmokecolor p-16 md:p-12" id="footer">
      <div className="flex justify-end">
        <FaArrowUp
          className="text-3xl text-maincolor cursor-pointer mb-4"
          onClick={scrollToTop}
        />
      </div>
      <div className="flex flex-col gap-6 text-center items-center justify-center">
        <div className="flex-1 flex flex-col items-center justify-center bg-purewhite p-2 rounded-md">
          <img src={Logo} alt="logo" className="w-[200px]" />
          <p className="text-maincolor font-bold text-xs">RC No.: 7160194</p>
        </div>
        {/* <div className="flex-1">
          <h2 className="text-lg font-bold uppercase underline underline-offset-4 text-maincolor">
            About us
          </h2>
          <p className="md:text-sm">
            Janiba Ginger Limited is an export-oriented company with a mission
            to provide premium dried sliced ginger from Nigeria to the
            discerning markets of Europe and the Americas. Our venture
            capitalizes on the unique qualities of Nigerian ginger to cater to
            the growing demand for exotic and healthy food products....
          </p>
          <div className="mt-24 md:mt-8 text-center">
            <p className="mb-2 text-maincolor">Connect with us:</p>
            <div className="flex items-center justify-center gap-6 text-2xl">
              <Link href={"https://twitter.com"}>
                <FaTwitter className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link href={"https://facebook.com"}>
                <FaFacebook className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link href={"https://instagram.com"}>
                <FaInstagram className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
              <Link href={"https://linkedin.com"}>
                <FaLinkedin className="hover:text-maincolor relative hover:bottom-1 transition-all duration-300 ease-in-out" />
              </Link>
            </div>
          </div>
        </div> */}
        <div className="flex-1 gap-y-6 md:text-center md:items-center">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Location:
            </h2>
            <div>
              <div>
                <p className="flex gap-1 items-center">
                  <FaMapMarker className="text-seccolor" /> CB Finance House,
                  16E Ahmadu Bello Way. Kaduna. Kaduna State. , Nigeria
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Email:
            </h2>
            <p className="flex gap-1 items-center">
              <FaEnvelopeOpen className="text-seccolor" />:
              info@janibaintercontinentalnetwork.com
            </p>
          </div>
        </div>
        <div className="flex-1">
          <div className="space-y-2">
            <h2 className="text-lg md:text-sm font-bold uppercase underline underline-offset-4 text-seccolor">
              Phone:
            </h2>
            <p className="flex gap-1 items-center">
              <FaMobileAlt className="text-seccolor" />: +234 (818) 705 0789
            </p>
          </div>
        </div>
      </div>
      <div className="text-sm lg:mt-4 mt-4 text-center md:text-xs">
        &copy; 2023 Janiba Intercontinental Network Ltd. | All Right Reserved
      </div>
    </footer>
  );
}

export default Footer;
